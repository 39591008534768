import { File } from './index';

export function skipCoversFilter(file: File) {
  return String(file.filename).toLowerCase() !== 'cover';
}

export const images = ['jpg', 'jpeg', 'png', 'gif', 'webm', 'webp', 'svg'];
export const pdfs = ['pdf'];
export const videos = [
  'mp4',
  'webm',
  'm4v',
  'ogv',
  'mpg',
  'mov',
  'flv',
  'mpeg',
  'avi',
];

export const audios = ['mp3'];

export const otherDocuments = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'odt',
  'ppt',
  'pptx',
];

export const supportsPreview = (type: string) => {
  return [...images, ...pdfs, ...videos, ...otherDocuments].includes(type);
};
