import React from 'react';
import { AdapterProps } from '../types';
import { Controller, useFormContext } from 'react-hook-form';
import { useFormError } from '../utils';
import get from 'lodash/get';
import FormControl from '../../FormControl/FormControl';
import InputLabel from '../../InputLabel/InputLabel';
import { TextEditor } from '../../../index';
import FormError from '../../FormError/FormError';

export type TextAdapterProps = AdapterProps['editor'];

const EditorAdapter = (props: TextAdapterProps) => {
  const { label, name, rules } = props;
  const { formState, control, setValue } = useFormContext();
  const error = useFormError(get(formState.errors, name));
  const defaultValue = get(formState.defaultValues, name);

  return (
    <FormControl>
      {label && <InputLabel>{label}</InputLabel>}
      <Controller
        render={({ field: { ref, value, ...field } }) => (
          <TextEditor
            onEditorChange={(content) => {
              setValue(name, content);
            }}
            initialValue={defaultValue}
          />
        )}
        control={control}
        name={name}
        rules={rules}
      />
      <FormError error={error} />
    </FormControl>
  );
};

export default EditorAdapter;
