import { FormRegistry } from './types';
import TextAdapter from './Adapter/TextAdapter';
import LongTextAdapter from './Adapter/LongTextAdapter';
import PasswordAdapter from './Adapter/PasswordAdapter';
import EmailAdapter from './Adapter/EmailAdapter';
import SelectAdapter from './Adapter/SelectAdapter';
import DateAdapter from './Adapter/DateAdapter';
import AutocompleteAdapter from './Adapter/AutocompleteAdapter';
import ServerAutocomplete from './Adapter/ServerAutocomplete';
import FileAdapter from './Adapter/FileAdapter';
import SelectCreatableAdapter from './Adapter/SelectCreatableAdapter';
import CheckboxAdapter from './Adapter/CheckboxAdapter';
import GroupAdapter from './Adapter/GroupAdapter';
import ColorAdapter from './Adapter/ColorAdapter';
import EditorAdapter from './Adapter/EditorAdapter';

const registry: FormRegistry = {
  text: TextAdapter,
  longtext: LongTextAdapter,
  number: TextAdapter,
  email: EmailAdapter,
  password: PasswordAdapter,
  select: SelectAdapter,
  date: DateAdapter,
  autocomplete: AutocompleteAdapter,
  'server-autocomplete': ServerAutocomplete,
  file: FileAdapter,
  'select-creatable': SelectCreatableAdapter,
  checkbox: CheckboxAdapter,
  boolean: CheckboxAdapter,
  group: GroupAdapter,
  color: ColorAdapter,
  editor: EditorAdapter,
} as const;

export default registry;
