import React, { useEffect } from 'react';
import { useModal } from '../../../components/v2/Modal/useModal';
import { Dir } from '..';
import { DirPermissionsForm } from '../components/Permissions/DirPermissionsForm';
import { useDirsActions, useSingleDir } from '../hooks';
import Section from 'components/v2/Section/Section';
import Loader from 'components/v2/Loader/Loader';
import Typography from '../../../components/v2/Typography/Typography';
import Spacer from '../../../components/v2/Spacer/Spacer';
import { refetchActiveQueries } from '../../../utils/QueryClientProvider';

const PermissionsForWithStateAdapter = ({ id }: any) => {
  const { readDir } = useDirsActions();
  const { dir: dirFromState = null } = useSingleDir(id);

  useEffect(() => {
    readDir({ id });

    return () => {
      void refetchActiveQueries();
    };
  }, []);

  if (!dirFromState || !dirFromState.path) {
    return <Loader />;
  }

  return <DirPermissionsForm dir={dirFromState!} />;
};

const useDirectoryPermissions = (dir: Pick<Dir, 'id'>) => {
  const modal = useModal();

  return () =>
    modal.open(() => (
      <Section>
        <Typography variant="h2" align="center">
          Gebruikersrechten
        </Typography>
        <Spacer size={2} />
        <PermissionsForWithStateAdapter id={dir.id} />
      </Section>
    ));
};

export default useDirectoryPermissions;
