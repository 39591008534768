import React from 'react';
import { useDirectoryActions } from '../../../modules/dirs/components/DirectoryActions';
import useContextMenu from '../ContextMenu/useContextMenu';
import Table from '../Table/Table';
import { DirectoryResource, FileResource, Resource } from './utils';
import { useFileActions } from 'modules/files/components/FileActions';
import { Stack } from '../Stack/Stack';
import { Link } from 'react-router-dom';

const FileNameCellContent = ({ original, icon, name }: FileResource) => {
  const actions = useFileActions(original);
  const { ref } = useContextMenu<HTMLDivElement>({ actions });

  return (
    <Table.CellContent ref={ref}>
      {icon}
      {name}
    </Table.CellContent>
  );
};

const DirectoryNameCellContent = ({
  original,
  icon,
  name,
}: DirectoryResource) => {
  const actions = useDirectoryActions(original);
  const { ref } = useContextMenu<HTMLDivElement>({ actions });
  const pathLength = (original.path || []).length;

  return (
    <Table.CellContent ref={ref}>
      {icon}
      <Stack direction="column">
        <span>{name}</span>
        {pathLength > 1 && (
          <div>
            {(original.path || []).map((item, index) => (
              <>
                <small>
                  {index > 0 && ' / '}
                  <Link to={`/v2/directory/${item.id}`}>{item.title}</Link>
                </small>
              </>
            ))}
          </div>
        )}
      </Stack>
    </Table.CellContent>
  );
};

export const NameCellContent = (item: Resource) => {
  const { type } = item;

  if (type === 'file') {
    return <FileNameCellContent {...item} />;
  }

  if (type === 'directory') {
    return <DirectoryNameCellContent {...item} />;
  }
};
