import { Dropdown, MenuButton } from '@mui/base';
import React from 'react';
import classNames from 'classnames';
import { useAuthActions } from '../../../modules/auth/hooks';
import { useTranslation } from 'utils/translation';
import { Menu, MenuItem } from '../Menu/Menu';
import css from './account-button.module.scss';
import Icon from '../Icon/Icon';
import { useNavigate } from 'utils/router';

type Props = {
  name: string;
};

const AccountButton: React.FC<Props> = ({ name }) => {
  const { t } = useTranslation();
  const { logout } = useAuthActions();
  const navigate = useNavigate();

  return (
    <div className={classNames(css.root)}>
      <Dropdown>
        <MenuButton className={css.button}>{name}</MenuButton>
        <Menu>
          <MenuItem
            onClick={() => navigate('/account')}
            icon={<Icon name="fa-regular fa-user" />}
          >
            {t('Edit Profile')}
          </MenuItem>
          <MenuItem
            onClick={logout}
            icon={<Icon name="fa-regular fa-sign-out" />}
          >
            {t('Logout')}
          </MenuItem>
        </Menu>
      </Dropdown>
    </div>
  );
};

export default AccountButton;
