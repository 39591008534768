import React from 'react';
import { useModal } from '../../../components/v2/Modal/useModal';
import Section from '../../../components/v2/Section/Section';
import { useTranslation } from 'utils/translation';
import { refetchActiveQueries } from '../../../utils/QueryClientProvider';
import wait from '../../../utils/wait';
import { MoveForm } from '../components/MoveForm';
import { useDirsActions } from '../hooks';
import { Dir } from '../index';

function useDirectoryMove({ id }: Pick<Dir, 'id'>) {
  const { t } = useTranslation();
  const modal = useModal();
  const { moveDir } = useDirsActions();

  const filterDirectories = React.useCallback(
    (node: Pick<Dir, 'id'>) => {
      return node.id !== id;
    },
    [id],
  );

  return () => {
    const Component = () => (
      <Section>
        <MoveForm
          title={t('Move directory')}
          filter={filterDirectories}
          onCancel={() => modal.close(Component)}
          onSubmit={async (dirId: number) => {
            moveDir({
              id: id,
              parent: dirId,
            });

            await wait(300);
            void refetchActiveQueries();
            void modal.close(Component);
          }}
        />
      </Section>
    );

    modal.open(Component);
  };
}

export default useDirectoryMove;
