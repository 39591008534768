import { useQuery } from '@tanstack/react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useShallowEqualEffect } from '../../utils/use-shallow-effect';
import { usersActions, usersPaginateActions } from './actions';
import {
  selectUsers,
  selectUser,
  isFetching,
  isUserFetching,
} from './selectors';
import { ChangeProfilePasswordPayload, SelectUsersParams, User } from './index';
import { RootState } from '../../store';
import { useCallback } from 'react';
import { useApiCall } from '../@common/hooks';
import {
  changeProfilePassword,
  changeUserPassword,
  createUser,
  createUserApiCredentials,
  fetchUserApiCredentials,
  regeneratePasswordLink,
  removeUserApiCredentials,
  updateProfile,
} from './service/api';
import { useMutation } from '@tanstack/react-query';
import * as api from './service/api';

export const useUserActions = () => {
  const dispatch = useDispatch();
  const changeStatus = (user: User, status: boolean) =>
    dispatch(
      usersActions.statusChange.trigger({
        user,
        status,
      }),
    );

  return {
    changeStatus,
  };
};

export const useUserQuery = (id: User['id']) => {
  return useQuery({
    queryKey: ['api_user_details', id],
    queryFn: () => api.readUser({ id }),
  });
};

export function useSingleUser(id: number) {
  const dispatch = useDispatch();
  const user = useSelector(
    (state: RootState) => selectUser(state, id),
    shallowEqual,
  );
  const isFetching = useSelector(
    (state: RootState) => isUserFetching(state, id),
    shallowEqual,
  );
  const readUser = useCallback(
    () => dispatch(usersActions.read.trigger({ id })),
    [dispatch, id],
  );

  return {
    readUser,
    user,
    isFetching,
  };
}

export function useUsersList(params: SelectUsersParams = {}) {
  const dispatch = useDispatch();

  const users = useSelector(
    (state: RootState) => selectUsers(state, params),
    shallowEqual,
  ) as User[];
  const isLoading = useSelector(isFetching, shallowEqual);
  const fetchUsers = () => dispatch(usersActions.fetch.trigger());
  const deleteUser = (id: number) =>
    dispatch(usersActions.delete.trigger({ id }));
  const changePage = (page: number) =>
    dispatch(usersPaginateActions.changePage({ page }));
  const changePerPage = (page: number) =>
    dispatch(usersPaginateActions.changePerPage({ page }));

  return {
    users,
    isLoading,
    fetchUsers,
    deleteUser,
    changePage,
    changePerPage,
  };
}

export function useUserPasswordChange(id: User['id']) {
  return useMutation({
    mutationFn: ({ password }: { password: string }) =>
      changeUserPassword({ id }, password),
  });
}

export function useUserPasswordChangeApiCall() {
  return useApiCall({
    provider: changeUserPassword,
  });
}

/** @deprecated */
export function useProfileUpdate() {
  return useApiCall({
    provider: updateProfile,
  });
}

export function useProfileUpdateMutation() {
  return useMutation({
    mutationFn: updateProfile,
  });
}

/** @deprecated */
export function useProfilePasswordChange() {
  return useApiCall({
    provider: changeProfilePassword,
  });
}

export function usePasswordChange() {
  return useMutation<any, any, ChangeProfilePasswordPayload>({
    mutationFn: changeProfilePassword,
  });
}

export function useUserApiCredentials() {
  return useApiCall({
    provider: fetchUserApiCredentials,
  });
}

export function useApiCredentials(id: User['id']) {
  return useQuery({
    queryKey: ['api_user_api_credentials', id],
    queryFn: () => fetchUserApiCredentials({ id }),
  });
}

export function useCreateUserApiCredentials() {
  return useApiCall({
    provider: createUserApiCredentials,
  });
}

export function useRemoveUserApiCredentials() {
  return useApiCall({
    provider: removeUserApiCredentials,
  });
}

export function useCreateApiCredentials(id: User['id']) {
  return useMutation({
    mutationFn: () => createUserApiCredentials({ id }),
  });
}

export function useRemoveApiCredentials(id: User['id']) {
  return useMutation<any, any, { clientId: string }>({
    mutationFn: ({ clientId }) => removeUserApiCredentials({ id }, clientId),
  });
}

export function useRegeneratePasswordLink() {
  return useApiCall({
    provider: regeneratePasswordLink,
  });
}

export const useUserCreate = () =>
  useMutation<User, any, any>({
    mutationFn: createUser,
  });

export const useUsersQuery = (params: Record<string, unknown>) => {
  const query = useQuery({
    queryKey: ['users_index'],
    queryFn: () => api.fetchUsers(params),
  });

  useShallowEqualEffect(() => {
    void query.refetch();
  }, [params]);

  return query;
};
