import { combineReducers } from 'redux';
import { uiReducer } from '../modules/ui/reducer';
import { authReducer } from '../modules/auth/reducer';
import { usersReducer, userPaginationReducer } from '../modules/users/reducer';
import { groupsReducer } from '../modules/user-groups/reducer';
import { dirsReducer, rootDirsReducer } from '../modules/dirs/reducer';
import { dirAccessReducer } from '../modules/dirs-access/reducers';
import { filesReducer, uploadReducer } from '../modules/files/reducers';
import { basketReducer } from '../modules/basket/reducer';
import { layerFieldsReducer } from '../modules/intelligent-layer/reducer';
import { logout } from '../modules/auth/actions';

export const reducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  users: usersReducer,
  groups: groupsReducer,
  files: filesReducer,
  upload: uploadReducer,
  dirs: dirsReducer,
  basket: basketReducer,
  rootDirs: rootDirsReducer,
  dirAccess: dirAccessReducer,
  dirsFieldLayer: layerFieldsReducer,
  usersPagination: userPaginationReducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === logout.TRIGGER) {
    const nextState = {
      // keep UI key in state to prevent system settings
      ui: state.ui,
    };

    return reducer(nextState as any, action);
  }

  return reducer(state, action);
};
