import React from 'react';
import clsx from 'clsx';
import css from './stack.module.scss';

type StackProps = React.PropsWithChildren<{
  direction?: 'row' | 'column';
  justify?: 'center' | 'space-between' | 'space-around' | 'space-evenly';
  align?: 'center' | 'start' | 'end';
  gap?: number;
  className?: string;
}>;

export function Stack(props: StackProps) {
  const { className, children, direction = 'row', justify, align, gap } = props;

  return (
    <div
      style={{
        gap: gap ? gap * 8 : undefined,
      }}
      className={clsx(
        css.root,
        className,
        css[direction],
        justify && css[`justify-${justify}`],
        align && css[`align-${align}`],
      )}
    >
      {children}
    </div>
  );
}
